import styled, { css } from 'styled-components';
import { breakpointsMedia } from '@nubank/nuds-web/styles/breakpoints';

export const SectionContent = styled.div`
  min-height: 100vh;
  width: 100%;
  display: grid;
  grid-template-columns: 32px repeat(10, 1fr) 32px;
  grid-template-rows: 64px auto 64px auto 64px;

  ${breakpointsMedia({
    md: css`
      grid-template-columns: 64px repeat(10, 1fr) 64px;
      grid-template-rows: 64px 1fr 64px;
    `,
  })}
`;

SectionContent.displayName = 'SectionContent';
