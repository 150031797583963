import styled, { css } from 'styled-components';
import { breakpointsMedia } from '@nubank/nuds-web/styles/breakpoints';

import Section from 'components/structure/Section';

export const StyledSection = styled(Section)`
  background-position-y: top;
  align-items: flex-start;
  
  ${breakpointsMedia({
    xs: css`
      min-height: 456px;
    `,
    md: css`
      min-height: 576px;
    `,
    lg: css`
      min-height: 680px;
    `,
  })}
`;

StyledSection.displayName = 'StyledSection';
