import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Grid from '@nubank/nuds-web/components/DeprecatedGrid/DeprecatedGrid';
import Box from '@nubank/nuds-web/components/Box/Box';
import { NuDsContext } from '@nubank/nuds-web/components/NuDSProvider/NuDSProvider';

import Image from '@nubank/www-latam-commons/components/Image/Image';

import { StyledTypography as Typography } from './styles/Typography';
import { StyledLink as Link } from './styles/Link';

function SectionFGTS({ index }) {
  const { formatMessage } = useContext(NuDsContext);

  return (
    <Grid
      row
      id="fgts"
      spacingHorizontal={{ xs: '0x' }}
      spacingVertical={{ xs: '12x', md: '16x' }}
      alignItems={{ lg: 'center' }}
    >
      <Grid
        offset={{ lg: 1 }}
        col={{
          xs: 12,
          md: 6,
          lg: 6,
        }}
      >
        <Box
          paddingHorizontal={{ xs: '4x' }}
          marginBottom={{ xs: '12x', md: '0x' }}
        >
          <Typography
            variant="heading2"
            color="black"
            intlKey={`cms.homePage.homeCardsArea.${index}.cardTitle`}
            whiteSpace="pre-line"
          />
          <Link
            variant="action"
            color="primary"
            href={formatMessage({ id: `cms.homePage.homeCardsArea.${index}.cardCtaLink` })}
            intlKey={`cms.homePage.homeCardsArea.${index}.cardCtaText`}
          />
        </Box>
      </Grid>

      <Grid
        spacingHorizontal={{ xs: '0x' }}
        col={{
          xs: 12,
          md: 6,
          lg: 4,
        }}
      >
        <Image
          loading="lazy"
          altIntlKey="HOME.FGTS.IMAGE_ALT"
          srcSet={{
            xs: 'girl-holding-card/girl-holding-card@xs.jpg',
            lg: 'girl-holding-card/girl-holding-card@lg.jpg',
          }}
        />
      </Grid>
    </Grid>
  );
}

SectionFGTS.propTypes = {
  index: PropTypes.number.isRequired,
};

export default React.memo(SectionFGTS);
