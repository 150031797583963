import styled, { css } from 'styled-components';
import { breakpointsMedia } from '@nubank/nuds-web/styles/breakpoints';

import Section from 'components/structure/Section';

const StyledSection = styled(Section)`  
  ${breakpointsMedia({
    xs: css`
      align-items: flex-start;
      background-position: right;
      min-height: 456px;
      `,
    md: css`
      min-height: 576px;
    `,
    lg: css`
      min-height: 680px;
    `,
  })}
`;

StyledSection.displayName = 'StyledSection';

export default StyledSection;
