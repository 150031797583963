import styled, { css } from 'styled-components';
import { nuDSColor } from '@nubank/nuds-web/styles/themeUtils';
import { breakpointsMedia } from '@nubank/nuds-web/styles/breakpoints';

export const FormContent = styled.div`
  position: relative;
  padding: 1.5rem;
  border-radius: 1rem;
  background-color: ${nuDSColor('white')};
  max-width: 20.625rem;
  /* Remove the margin rules if we're able to remove the set width from the NuDS FormWrapper */
  margin: auto;

  ${breakpointsMedia({
    md: css`
      margin-right: 0;
    `,
  })}
`;

FormContent.displayName = 'FormContent';
