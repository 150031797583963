import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { NuDsContext } from '@nubank/nuds-web/components/NuDSProvider/NuDSProvider';

import ImagePreload from '@nubank/www-latam-commons/components/ImagePreload/ImagePreload';
import websitePage, { headerTypes } from 'hoc/websitePage/websitePage';
import withApplicationFormDrawer from 'hoc/withApplicationFormDrawer/withApplicationFormDrawer';

import SectionForm, { HERO_IMAGE } from './patterns/SectionForm/SectionForm';
import SectionEasynvest from './patterns/SectionEasynvest/SectionEasynvest';
import SectionUltraviolet from './patterns/SectionUltraviolet/SectionUltraviolet';
import SectionFGTS from './patterns/SectionFGTS/SectionFGTS';
import SectionNuConta from './patterns/SectionNuConta/SectionNuConta';
import SectionCreditCard from './patterns/SectionCreditCard/SectionCreditCard';
import SectionAboutUs from './patterns/SectionAboutUs/SectionAboutUs';
import SectionNuCommunity from './patterns/SectionNuCommunity/SectionNuCommunity';
import SectionBlog from './patterns/SectionBlog/SectionBlog';

function Home({ onFormVisibilityChange }) {
  const { formatMessage } = useContext(NuDsContext);

  const CARDS_MAP = {
    SectionFGTS,
    SectionEasynvest,
    SectionNuConta,
    SectionCreditCard,
    SectionAboutUs,
    SectionNuCommunity,
    SectionBlog,
    SectionUltraviolet,
  };

  const homeCardsArea = formatMessage({ id: 'cms.homePage.homeCardsArea' });

  return (
    <>
      <ImagePreload srcSet={HERO_IMAGE} />
      <SectionForm onFormVisibilityChange={onFormVisibilityChange} />
      {homeCardsArea.map((homeCard, index) => {
        const CardComponent = CARDS_MAP[homeCard.cardComponentType];

        if (!CardComponent) return null;

        return (
          <CardComponent
            key={homeCard.cardTitle}
            index={index}
          />
        );
      })}
    </>
  );
}

Home.propTypes = {
  onFormVisibilityChange: PropTypes.func.isRequired,
};

export default withApplicationFormDrawer(
  websitePage({
    routeKey: 'HOME',
    header: headerTypes.PRODUCT_PAGE,
  })(Home),
);
