import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Typography from '@nubank/nuds-web/components/Typography/Typography';

import ShortForm from 'patterns/ShortForm/ShortForm';
import ProductPageOpenFormSection from 'patterns/ProductPageOpenFormSection/ProductPageOpenFormSection';
import RegistrationUnavailableLanguage from 'patterns/RegistrationUnavailableLanguage/RegistrationUnavailableLanguage';
import useOpenFormVisibility from 'utils/hooks/useOpenFormVisibility/useOpenFormVisibility';
import { DEFAULT_LOCALE } from 'utils/i18nUtils';

export const HERO_IMAGE = {
  xs: {
    '1x': 'home-hero/home-hero-xs@1x.jpg',
    '2x': 'home-hero/home-hero-xs@2x.jpg',
    '3x': 'home-hero/home-hero-xs@3x.jpg',
  },
  md: {
    '1x': 'home-hero/home-hero-md@1x.jpg',
    '2x': 'home-hero/home-hero-md@2x.jpg',
    '3x': 'home-hero/home-hero-md@3x.jpg',
  },
  lg: {
    '1x': 'home-hero/home-hero-lg@1x.jpg',
    '2x': 'home-hero/home-hero-lg@2x.jpg',
    '3x': 'home-hero/home-hero-lg@3x.jpg',
  },
};

function SectionForm({ onFormVisibilityChange }) {
  const intl = useIntl();
  const [ref, isFormHidden] = useOpenFormVisibility();

  useEffect(() => {
    onFormVisibilityChange(isFormHidden);
  }, [isFormHidden]);

  return (
    <ProductPageOpenFormSection
      ref={ref}
      backgroundColor="primary.default"
      backgroundSize="cover"
      backgroundPosition="center center"
      backgroundImage={HERO_IMAGE}
    >
      <ProductPageOpenFormSection.Container
        gridTemplateRows={{
          xs: '14rem auto 6rem 1fr',
          md: '1fr',
        }}
      >
        <ProductPageOpenFormSection.Heading
          gridColumn={{
            xs: '1 / span 4',
            lg: '2 / span 6',
            xl: '2 / span 5',
          }}
          gridRow={{
            xs: '2',
            md: 'initial',
          }}
        >
          <Typography
            variant="heading2"
            tag="h1"
            color="white"
            marginBottom="4x"
            intlKey="cms.homePage.homeHeroTitle"
            whiteSpace="pre-line"
          />
          <Typography
            variant="heading4"
            tag="h2"
            color="white"
            marginBottom="4x"
            intlKey="cms.homePage.homeHeroDescription"
          />
        </ProductPageOpenFormSection.Heading>
        <ProductPageOpenFormSection.FormBlock>
          {intl.locale === DEFAULT_LOCALE ? (
            <ShortForm />
          ) : (
            <RegistrationUnavailableLanguage isOpenForm isShortForm />
          )}
        </ProductPageOpenFormSection.FormBlock>
      </ProductPageOpenFormSection.Container>
    </ProductPageOpenFormSection>
  );
}

SectionForm.propTypes = {
  onFormVisibilityChange: PropTypes.func.isRequired,
};

export default React.memo(SectionForm);
