import styled, { css } from 'styled-components';
import { breakpointsMedia } from '@nubank/nuds-web/styles/breakpoints';

import ImageBlock from 'components/structure/ImageBlock';

export const CreditCardImageBlock = styled(ImageBlock)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 48px;

  img {
    max-height: 288px;
  }

  ${breakpointsMedia({
    sm: css`
      img {
        max-height: 360px;
      }
    `,
    md: css`
      img {
        max-height: 480px;
      }
    `,
    lg: css`
      img {
        height: auto;
        max-height: 80vh;
      }
    `,
  })}
`;

CreditCardImageBlock.displayName = 'CreditCardImageBlock';
