import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { NuDsContext } from '@nubank/nuds-web/components/NuDSProvider/NuDSProvider';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Link from '@nubank/nuds-web/components/Link/Link';

import Section from 'components/structure/Section';
import Block from 'components/structure/Block/Block';
import { paddingBuilder } from 'styles/themeUtils';

import { CreditCardImageBlock } from './styles/CreditCardImageBlock';

function SectionCreditCard({ index }) {
  const { formatMessage } = useContext(NuDsContext);

  return (
    <Section
      backgroundColor="white"
      id="credito"
    >
      <Block
        blockWidth="50%"
        padding={{
          desktop: paddingBuilder.right(0),
        }}
      >
        <Link
          color="primary"
          href={formatMessage({ id: `cms.homePage.homeCardsArea.${index}.cardPreTitleLink` })}
        >
          <Typography
            variant="heading2"
            color="primary"
            intlKey={`cms.homePage.homeCardsArea.${index}.cardPreTitleText`}
          />
        </Link>
        <Typography
          variant="heading2"
          tag="p"
          color="black"
          marginBottom="8x"
          intlKey={`cms.homePage.homeCardsArea.${index}.cardTitle`}
          whiteSpace="pre-line"
        />
        <Link
          variant="action"
          color="primary"
          href={formatMessage({ id: `cms.homePage.homeCardsArea.${index}.cardCtaLink` })}
          intlKey={`cms.homePage.homeCardsArea.${index}.cardCtaText`}
        />
      </Block>
      <FormattedMessage id="CARD.CREDIT_CARD.IMAGE_ALT">
        {([txt]) => (
          <CreditCardImageBlock
            loading="lazy"
            alt={txt}
            blockWidth="40%"
            backgroundImage="holding-card-vertical/holding-card-vertical.png"
            noPadding
          />
        )}
      </FormattedMessage>
    </Section>
  );
}

SectionCreditCard.propTypes = {
  index: PropTypes.number.isRequired,
};

export default React.memo(SectionCreditCard);
