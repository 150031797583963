import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { NuDsContext } from '@nubank/nuds-web/components/NuDSProvider/NuDSProvider';
import Link from '@nubank/nuds-web/components/Link/Link';
import Typography from '@nubank/nuds-web/components/Typography/Typography';

import { StyledTextBlock, StyledSection } from './styles/StyledSectionCommunity';

function SectionNuCommunity({ index }) {
  const { formatMessage } = useContext(NuDsContext);

  return (
    <StyledSection
      backgroundColor="#2c1c25"
      backgroundImageUrl={{
        xs: {
          '1x': 'three-young-people-outdoors-leaning-against-the-wall/three-young-people-outdoors-leaning-against-the-wall-xs@1x.jpg',
          '2x': 'three-young-people-outdoors-leaning-against-the-wall/three-young-people-outdoors-leaning-against-the-wall-xs@2x.jpg',
          '3x': 'three-young-people-outdoors-leaning-against-the-wall/three-young-people-outdoors-leaning-against-the-wall-xs@3x.jpg',
        },
        md: {
          '1x': 'three-young-people-outdoors-leaning-against-the-wall/three-young-people-outdoors-leaning-against-the-wall-md@1x.jpg',
          '2x': 'three-young-people-outdoors-leaning-against-the-wall/three-young-people-outdoors-leaning-against-the-wall-md@2x.jpg',
          '3x': 'three-young-people-outdoors-leaning-against-the-wall/three-young-people-outdoors-leaning-against-the-wall-md@3x.jpg',
        },
        lg: {
          '1x': 'three-young-people-outdoors-leaning-against-the-wall/three-young-people-outdoors-leaning-against-the-wall-lg@1x.jpg',
          '2x': 'three-young-people-outdoors-leaning-against-the-wall/three-young-people-outdoors-leaning-against-the-wall-lg@2x.jpg',
          '3x': 'three-young-people-outdoors-leaning-against-the-wall/three-young-people-outdoors-leaning-against-the-wall-lg@3x.jpg',
        },
      }}
    >
      <StyledTextBlock>
        <Typography
          variant="heading2"
          color="white"
          marginBottom={{ xs: '2x' }}
          intlKey={`cms.homePage.homeCardsArea.${index}.cardPreTitleText`}
        />

        <Typography
          variant="heading2"
          tag="p"
          color="white"
          marginBottom={{ xs: '8x' }}
          intlKey={`cms.homePage.homeCardsArea.${index}.cardTitle`}
          whiteSpace="pre-line"
        />

        <Link
          href={formatMessage({ id: `cms.homePage.homeCardsArea.${index}.cardCtaLink` })}
          variant="action"
          color="white"
          maxWidth={{ xs: '230px', md: '500px' }}
          intlKey={`cms.homePage.homeCardsArea.${index}.cardCtaText`}
        />
      </StyledTextBlock>
    </StyledSection>
  );
}

SectionNuCommunity.propTypes = {
  index: PropTypes.number.isRequired,
};

export default React.memo(SectionNuCommunity);
