import styled, { css } from 'styled-components';
import { breakpointsMedia } from '@nubank/nuds-web/styles/breakpoints';

export const CopyContent = styled.div`
  grid-column: 2 / 12;
  grid-row: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  ${breakpointsMedia({
    md: css`
      grid-row: 2;
      grid-column: 2 / 7;
    `,
  })}
`;

CopyContent.displayName = 'CopyContent';
