import styled, { css } from 'styled-components';
import { breakpointsMedia } from '@nubank/nuds-web/styles/breakpoints';

import Block from 'components/structure/Block/Block';

export const TextBlock = styled(Block)`
  min-height: ${props => (props.autoHeight ? 'auto' : '300px')};
  align-self: center;
  justify-content: center;

  ${breakpointsMedia({
    xs: css`
      justify-content: center;
      min-width: 312px;
      padding: 48px 24px;
    `,
    lg: css`
      padding: 64px;
      margin-left: 99px;
    `,
  })}
`;

TextBlock.displayName = 'TextBlock';
