import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Link from '@nubank/nuds-web/components/Link/Link';
import { DEFAULT_BREAKPOINTS } from '@nubank/nuds-web/styles/breakpoints';
import { NuDsContext } from '@nubank/nuds-web/components/NuDSProvider/NuDSProvider';

import { requireImage } from '@nubank/www-latam-commons/utils/requireImage';
import Section from 'components/structure/Section';

import { SectionContent } from './styles/SectionContent';
import { CopyContent } from './styles/CopyContent';
import { DeviceImage } from './styles/DeviceImage';

const imageAssets = [
  {
    media: `(min-width: ${DEFAULT_BREAKPOINTS.xl}px)`,
    srcSet: 'nuconta-inclined-device/nuconta-inclined-device@2x.png',
  },
  {
    media: `(min-width: ${DEFAULT_BREAKPOINTS.lg}px)`,
    srcSet: 'nuconta-inclined-device/nuconta-inclined-device@2x.png',
  },
  {
    media: `(min-width: ${DEFAULT_BREAKPOINTS.md}px)`,
    srcSet: 'nuconta-inclined-device/nuconta-inclined-device@2x.png',
  },
  {
    media: `(min-width: ${DEFAULT_BREAKPOINTS.sm}px)`,
    srcSet: 'nuconta-inclined-device/nuconta-inclined-device@0.75x.png',
  },
  {
    media: `(min-width: ${DEFAULT_BREAKPOINTS.xs}px)`,
    srcSet: 'nuconta-inclined-device/nuconta-inclined-device@0.5x.png',
  },
];

function renderImages(assets) {
  return assets.map(asset => (
    <React.Fragment key={asset.srcSet}>
      <source
        media={asset.media}
        srcSet={requireImage(asset.srcSet, 'webp')}
        type="image/webp"
      />

      <source
        media={asset.media}
        srcSet={requireImage(asset.srcSet)}
        type="image/jpeg"
      />
    </React.Fragment>
  ));
}

function SectionNuConta({ index }) {
  const { formatMessage } = useContext(NuDsContext);

  return (
    <Section backgroundColor="white.dark">
      <SectionContent>
        <DeviceImage>
          {renderImages(imageAssets)}

          <FormattedMessage id="HOME.NUCONTA.IMAGE.ALT">
            {([alt]) => (
              <>
                <source
                  srcSet={requireImage('nuconta-inclined-device/nuconta-inclined-device@1x.png', 'webp')}
                  type="image/webp"
                />

                <img
                  src={requireImage('nuconta-inclined-device/nuconta-inclined-device@1x.png')}
                  alt={alt}
                  loading="lazy"
                />
              </>
            )}
          </FormattedMessage>
        </DeviceImage>

        <CopyContent>
          <Link href={formatMessage({ id: `cms.homePage.homeCardsArea.${index}.cardPreTitleLink` })}>
            <Typography
              variant="heading2"
              color="primary"
              colorVariant="default"
              intlKey={`cms.homePage.homeCardsArea.${index}.cardPreTitleText`}
            />
          </Link>
          <Typography
            variant="heading2"
            color="black"
            intlValues={{ br: <br /> }}
            marginBottom="8x"
            intlKey={`cms.homePage.homeCardsArea.${index}.cardTitle`}
            whiteSpace="pre-line"
          />

          <Link
            variant="action"
            color="primary"
            intlKey={`cms.homePage.homeCardsArea.${index}.cardCtaText`}
            href={formatMessage({ id: `cms.homePage.homeCardsArea.${index}.cardPreTitleLink` })}
          />
        </CopyContent>
      </SectionContent>
    </Section>
  );
}

SectionNuConta.propTypes = {
  index: PropTypes.number.isRequired,
};

export default React.memo(SectionNuConta);
