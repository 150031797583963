import styled, { css } from 'styled-components';
import { breakpointsMedia } from '@nubank/nuds-web/styles/breakpoints';

export const StyledSVG = styled.svg`
  width: 100%;

  ${breakpointsMedia({
    xs: css`max-width: 248px;`,
    md: css`max-width: 314px;`,
    lg: css`max-width: 397px;`,
  })}
`;

StyledSVG.displayName = 'StyledSVG';
