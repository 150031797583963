import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Button from '@nubank/nuds-web/components/Button/Button';
import TextField from '@nubank/nuds-web/components/TextField/TextField';
import Typography from '@nubank/nuds-web/components/Typography/Typography';

import NuDSFormBR from 'components/NuDSFormBR/NuDSFormBR';
import { getProspectType, MULTI_PRODUCT } from 'utils/prospectTypes';
import { trackShortFormSubmission, trackFieldFilling } from 'tracking/application/form';
import { useApplicationFormDrawerContext } from 'patterns/ApplicationFormDrawer/ApplicationFormContext';

import { getFieldProperties, normalizeFieldValidations } from './utils';
import { FormContent } from './styles/Form';

function ShortForm({
  formSubmitButtonLabel,
  formTitle,
  initialField,
  prospectTypeKey,
}) {
  const { formatMessage } = useIntl();
  const { openDrawer } = useApplicationFormDrawerContext();
  const uppercaseInitialField = initialField.toUpperCase();
  const fieldProps = getFieldProperties(initialField);
  const prospectType = getProspectType(prospectTypeKey);

  const handleSubmit = ({ values, setSubmitting }) => {
    trackShortFormSubmission({ prospectType: prospectType.type });
    openDrawer({
      initialField,
      initialFieldValue: values[initialField],
    });
    setSubmitting(false);
  };

  const handleOnBlur = event => {
    if (event.target.value) {
      trackFieldFilling({
        prospectType: prospectType.type,
        field: `${initialField}_short`,
      });
    }
  };

  return (
    <>
      <NuDSFormBR>
        <NuDSFormBR.Step
          noValidate
          onSubmit={handleSubmit}
          initialValues={{
            [initialField]: '',
          }}
          enableReinitialize
        >
          {({
            isDisabled,
            isSubmitting,
          }) => (
            <FormContent id="short-form">
              <Typography
                tag="h3"
                variant="heading4"
                color="black.default"
                marginBottom="8x"
                intlKey={formTitle}
              />
              <TextField
                id={`field-${initialField}`}
                name={initialField}
                type={fieldProps.type}
                label={formatMessage({ id: `COMMON.APPLICATION.LONG_LABEL.${uppercaseInitialField}` })}
                syncValidations={normalizeFieldValidations(fieldProps.validations, formatMessage)}
                onBlur={handleOnBlur}
              />
              <Button
                type="submit"
                variant="contained"
                styleVariant="primary"
                extended
                disabled={isDisabled || isSubmitting}
                intlKey={formSubmitButtonLabel}
                iconProps={{ name: 'arrow-right' }}
              />
            </FormContent>
          )}
        </NuDSFormBR.Step>
      </NuDSFormBR>
    </>
  );
}

ShortForm.defaultProps = {
  formTitle: 'SHORT_FORM.MULTI_PRODUCT.FORM_TITLE',
  formSubmitButtonLabel: 'COMMON.WIDGET.SHORT_FORM.CONTINUE_BUTTON',
  initialField: 'cpf',
  prospectTypeKey: MULTI_PRODUCT,
};

ShortForm.propTypes = {
  formSubmitButtonLabel: PropTypes.string,
  formTitle: PropTypes.string,
  initialField: PropTypes.oneOf(['email', 'name', 'cpf', 'cnpj']),
  prospectTypeKey: PropTypes.string,
};

export default React.memo(ShortForm);
