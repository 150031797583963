import styled, { css } from 'styled-components';
import { breakpointsMedia } from '@nubank/nuds-web/styles/breakpoints';

import Section from 'components/structure/Section';

import { TextBlock } from '../../styles/TextBlock';

export const StyledTextBlock = styled(TextBlock)`
  ${breakpointsMedia({
    xs: css`
      min-height: 300px;
      padding: 40px 24px;
    `,
    md: css`
      min-width: 600px;
    `,
    lg: css`
      margin-left: 99px;
    `,
  })}
  `;

StyledTextBlock.displayName = 'StyledTextBlock';

export const StyledSection = styled(Section)`
  ${breakpointsMedia({
    xs: css`
      min-height: 456px;
    `,
    md: css`
      min-height: 576px;
      align-items: flex-start;
    `,
    lg: css`
      min-height: 680px;
    `,
  })}
  `;

StyledSection.displayName = 'StyledSection';

