import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { NuDsContext } from '@nubank/nuds-web/components/NuDSProvider/NuDSProvider';
import Link from '@nubank/nuds-web/components/Link/Link';
import Typography from '@nubank/nuds-web/components/Typography/Typography';

import { TextBlock } from '../styles/TextBlock';

import { StyledSection } from './styles/StyledSection';

function SectionBlog({ index }) {
  const { formatMessage } = useContext(NuDsContext);

  return (
    <StyledSection
      backgroundColor="#9c43cd"
      backgroundImageUrl={{
        xs: {
          '1x': 'man-with-cellphone/man-with-cellphone-xs@1x.jpg',
          '2x': 'man-with-cellphone/man-with-cellphone-xs@2x.jpg',
          '3x': 'man-with-cellphone/man-with-cellphone-xs@3x.jpg',
        },
        md: {
          '1x': 'man-with-cellphone/man-with-cellphone-md@1x.jpg',
          '2x': 'man-with-cellphone/man-with-cellphone-md@2x.jpg',
          '3x': 'man-with-cellphone/man-with-cellphone-md@3x.jpg',
        },
        lg: {
          '1x': 'man-with-cellphone/man-with-cellphone-lg@1x.jpg',
          '2x': 'man-with-cellphone/man-with-cellphone-lg@2x.jpg',
          '3x': 'man-with-cellphone/man-with-cellphone-lg@3x.jpg',
        },
      }}
    >
      <TextBlock autoHeight>
        <Typography
          variant="heading2"
          color="white"
          intlKey={`cms.homePage.homeCardsArea.${index}.cardTitle`}
          marginBottom="8x"
          whiteSpace="pre-line"
        />

        <Link
          href={formatMessage({ id: `cms.homePage.homeCardsArea.${index}.cardCtaLink` })}
          variant="action"
          color="white"
          intlKey={`cms.homePage.homeCardsArea.${index}.cardCtaText`}
        />
      </TextBlock>
    </StyledSection>
  );
}

SectionBlog.propTypes = {
  index: PropTypes.number.isRequired,
};

export default React.memo(SectionBlog);
