import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Box from '@nubank/nuds-web/components/Box/Box';
import { NuDsContext } from '@nubank/nuds-web/components/NuDSProvider/NuDSProvider';

import { StyledLink } from './styles/Link';
import { StyledSVG } from './styles/SVG';

function SectionEasynvest({ index }) {
  const { formatMessage } = useContext(NuDsContext);

  return (
    <Box
      tag="section"
      minHeight="100vh"
      margin="auto"
      alignItems={{ lg: 'center' }}
      paddingHorizontal={{ xs: '8x', md: '16x' }}
      paddingVertical="16x"
      display="grid"
      gridRowGap="12x"
      gridColumnGap={{ xs: '4x', md: '8x' }}
      gridTemplateColumns={{ xs: 'repeat(4,1fr)', md: 'repeat(6,1fr)', lg: 'repeat(12,1fr)' }}
    >
      <Box
        gridColumn={{ xs: '1 / span 4', md: '2 / span 4', lg: '2 / span 4' }}
        alignSelf={{ xs: 'flex-end', lg: 'auto' }}
        display="flex"
        justifyContent="center"
      >
        <StyledSVG xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 397 212">
          <defs />
          <rect width="210.258" height="210.258" x="186.742" y="0.871094" fill="#F7F7ED" rx="105.129" />
          <path fill="#E6268C" fillRule="evenodd" d="M257.021 93.2981C246.721 94.0073 236.557 95.1454 226.552 96.6867C226.254 96.7325 226.083 97.0299 226.206 97.2929C230.761 106.844 235.843 116.275 241.476 125.552C247.107 134.828 253.16 143.739 259.592 152.278C259.763 152.509 260.126 152.524 260.321 152.306C263.343 148.892 266.295 145.416 269.174 141.88C264.198 125.932 260.14 109.711 257.021 93.2981Z" clipRule="evenodd" />
          <path fill="#FB0" fillRule="evenodd" d="M357.273 93.8642C338.795 87.4976 319.967 82.1948 300.882 77.9819C285.323 74.553 269.615 71.8449 253.807 69.8663C253.506 69.8263 253.249 70.0665 253.286 70.3524C254.321 77.9876 255.565 85.6399 257.021 93.298C258.013 93.2293 259.008 93.155 260.003 93.0978C273.795 92.2582 287.624 92.199 301.422 92.9205C301.754 92.9377 301.96 93.2751 301.805 93.5525C292.412 110.615 281.49 126.79 269.174 141.88C270.873 147.329 272.677 152.744 274.585 158.123C274.679 158.391 275.022 158.503 275.274 158.346C288.488 150.187 301.411 141.36 313.973 131.828C329.192 120.287 343.709 107.846 357.445 94.5734C357.679 94.3503 357.588 93.9728 357.273 93.8642Z" clipRule="evenodd" />
          <path fill="#4E0250" fillRule="evenodd" d="M301.805 93.5554C301.96 93.2751 301.757 92.9377 301.422 92.9205C287.79 92.2085 273.97 92.2457 260.003 93.0978C259.008 93.155 258.013 93.2265 257.021 93.298C260.138 109.711 264.196 125.932 269.174 141.88C281.49 126.791 292.412 110.615 301.805 93.5525" clipRule="evenodd" />
          <rect width="210.258" height="210.258" y="0.871094" fill="#820AD1" rx="105.129" />
          <path fill="#F5F5F5" d="M73.6905 82.4372C76.7894 79.0049 80.9002 77 85.7297 77 95.1378 77 101.376 84.2415 102.569 95.0898 102.956 98.6129 102.952 103.58 102.948 109.332 102.948 109.923 102.948 110.521 102.948 111.127V132.678H90.0096V116.738C90.0096 116.738 89.9832 103.095 89.9041 100.546 89.5569 89.4411 83.3209 82.4635 73.6891 82.456 70.7832 85.6926 69.2262 89.6499 68.9965 95.7008 68.965 96.5448 68.9729 99.5362 68.9834 103.479 68.9888 105.522 68.9949 107.82 68.9965 110.207 69.0061 120.597 68.9965 132.68 68.9965 132.68H56.0586V108.149C56.0586 107.308 56.044 106.46 56.0295 105.609 56.0001 103.897 55.9705 102.172 56.0586 100.464 56.2048 97.6163 56.672 94.817 57.9325 92.2164 60.8178 86.2586 66.7296 82.4226 73.032 82.4226 73.2523 82.4226 73.4725 82.4275 73.6905 82.4372zM153.941 110.536C154.029 108.828 154 107.102 153.971 105.391 153.956 104.54 153.941 103.692 153.941 102.851V78.3197H141.003C141.003 78.3197 140.994 90.4033 141.003 100.793 141.005 103.18 141.011 105.478 141.017 107.521 141.027 111.464 141.035 114.455 141.003 115.299 140.774 121.35 139.217 125.307 136.311 128.544 126.679 128.536 120.443 121.559 120.096 110.454 120.017 107.905 119.983 101.577 119.983 94.2493V78.3093L107.055 78.322V99.8734C107.055 100.479 107.055 101.077 107.054 101.667 107.05 107.42 107.047 112.387 107.434 115.91 108.625 126.758 114.862 134 124.271 134 129.1 134 133.211 131.995 136.31 128.563 136.528 128.572 136.748 128.577 136.968 128.577 143.27 128.577 149.182 124.741 152.067 118.784 153.328 116.183 153.795 113.384 153.941 110.536z" />
        </StyledSVG>
      </Box>
      <Box
        gridColumn={{ xs: '1 / span 4', md: '2 / span 4', lg: '7 / span 5' }}
        display="flex"
        flexDirection="column"
        alignItems={{ xs: 'flex-start', md: 'center', lg: 'flex-start' }}
      >
        <Typography
          variant="heading2"
          tag="h2"
          textAlign={{ md: 'center', lg: 'left' }}
          marginBottom={{ xs: '4x', md: '8x' }}
          intlKey={`cms.homePage.homeCardsArea.${index}.cardTitle`}
        />
        <Typography
          variant="heading4"
          tag="h3"
          textAlign={{ md: 'center', lg: 'left' }}
          marginBottom="4x"
          intlKey={`cms.homePage.homeCardsArea.${index}.description`}
        />
        <StyledLink
          variant="action"
          href={formatMessage({ id: `cms.homePage.homeCardsArea.${index}.cardCtaLink` })}
          intlKey={`cms.homePage.homeCardsArea.${index}.cardCtaText`}
        />
      </Box>
    </Box>
  );
}

SectionEasynvest.propTypes = {
  index: PropTypes.number.isRequired,
};

export default React.memo(SectionEasynvest);
