import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { NuDsContext } from '@nubank/nuds-web/components/NuDSProvider/NuDSProvider';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Link from '@nubank/nuds-web/components/Link/Link';
import Box from '@nubank/nuds-web/components/Box/Box';
import Grid from '@nubank/nuds-web/components/Grid/Grid';

import Image from '@nubank/www-latam-commons/components/Image/Image';

function SectionCreditCard({ index }) {
  const { formatMessage } = useContext(NuDsContext);

  return (
    <Box
      tag="section"
      display="flex"
      minHeight={{ md: '100vh' }}
      backgroundColor="white.dark"
      paddingVertical="16x"
    >
      <Grid
        alignItems={{ lg: 'center' }}
      >
        <Grid.Child gridColumn={{ xs: '1 / span 4', lg: '2 / span 5' }}>
          <Link
            href={formatMessage({ id: `cms.homePage.homeCardsArea.${index}.cardPreTitleLink` })}
            intlKey={`cms.homePage.homeCardsArea.${index}.cardPreTitleText`}
            typographyProps={{
              variant: 'heading2',
              color: 'primary',
            }}
          />
          <Typography
            variant="heading2"
            tag="p"
            color="black"
            whiteSpace="pre-line"
            marginBottom="8x"
            intlKey={`cms.homePage.homeCardsArea.${index}.cardTitle`}
          />
          <Link
            variant="action"
            href={formatMessage({ id: `cms.homePage.homeCardsArea.${index}.cardCtaLink` })}
            intlKey={`cms.homePage.homeCardsArea.${index}.cardCtaText`}
          />
        </Grid.Child>
        <Grid.Child gridColumn={{ xs: '1 / span 4', md: '3 / span 4', lg: '8 / span 4' }}>
          <Image
            loading="lazy"
            altIntlKey="HOME.ULTRAVIOLETA.IMAGE_ALT"
            srcSet={{
              xs: {
                '1x': 'ultraviolet-card-floating/ultraviolet-card-floating-xs@1x.png',
                '2x': 'ultraviolet-card-floating/ultraviolet-card-floating-xs@2x.png',
                '3x': 'ultraviolet-card-floating/ultraviolet-card-floating-xs@3x.png',
              },
              md: {
                '1x': 'ultraviolet-card-floating/ultraviolet-card-floating-md@1x.png',
                '2x': 'ultraviolet-card-floating/ultraviolet-card-floating-md@2x.png',
                '3x': 'ultraviolet-card-floating/ultraviolet-card-floating-md@3x.png',
              },
              lg: {
                '1x': 'ultraviolet-card-floating/ultraviolet-card-floating-lg@1x.png',
                '2x': 'ultraviolet-card-floating/ultraviolet-card-floating-lg@2x.png',
                '3x': 'ultraviolet-card-floating/ultraviolet-card-floating-lg@3x.png',
              },
            }}
          />
        </Grid.Child>
      </Grid>
    </Box>
  );
}

SectionCreditCard.propTypes = {
  index: PropTypes.number.isRequired,
};

export default React.memo(SectionCreditCard);
