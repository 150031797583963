import styled, { css } from 'styled-components';
import { breakpointsMedia } from '@nubank/nuds-web/styles/breakpoints';

export const DeviceImage = styled.picture`
  grid-area: 4 / 1 / 4 / 13;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-height: 404px;
  }

  ${breakpointsMedia({
    md: css`
      grid-area: 2 / 6 / 2 / 12;
      img {
        max-height: 479px;
        object-fit: scale-down;
      }
    `,
  })}
`;

DeviceImage.displayName = 'DeviceImage';
