import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import dynamic from 'next/dynamic';

import Block from 'components/structure/Block/Block';
import { responsiveAttributePropType } from 'styles/propTypes';
import { paddingBuilder } from 'styles/themeUtils';

const ResponsiveImages = dynamic(import('components/ResponsiveImage/ResponsiveImages'), { ssr: false });

const StyledBlock = styled(Block)`
  order: 2;

  /* TODO Improve how we pass height to responsive image component */
  .component-responsive-image,
  .component-responsive-image-size,
  img {
    height: ${({ imageHeight }) => imageHeight};
  }

  img {
    margin: 0 auto;
    display: block;
    object-fit: cover;
    width: ${({ imageWidth }) => imageWidth};
    max-height: ${({ maxHeight }) => maxHeight};
  }
`;

function ImageBlock(props) {
  const {
    backgroundImage,
    children,
    noPadding,
    blockWidth,
    imageWidth,
    imageHeight,
    className,
    maxHeight,
    padding,
    alt,
    loading,
  } = props;

  const blockPadding = padding || {
    default: paddingBuilder.all(noPadding ? 0 : 1),
    tablet: paddingBuilder.all(noPadding ? 0 : 2),
    desktop: paddingBuilder.all(noPadding ? 0 : 4),
  };

  return (
    <StyledBlock
      padding={blockPadding}
      className={className}
      noPadding={noPadding}
      blockWidth={blockWidth}
      imageWidth={imageWidth}
      imageHeight={imageHeight}
      maxHeight={maxHeight}
    >
      <ResponsiveImages fileName={backgroundImage} alt={alt} loading={loading} />
      {children}
    </StyledBlock>
  );
}

ImageBlock.defaultProps = {
  children: undefined,
  noPadding: false,
  blockWidth: '50%',
  imageWidth: 'auto',
  imageHeight: 'auto',
  className: 'structure-image-block',
  maxHeight: '100vh',
  padding: undefined,
  loading: undefined,
};

ImageBlock.propTypes = {
  alt: PropTypes.string.isRequired,
  backgroundImage: PropTypes.string.isRequired,
  blockWidth: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  imageHeight: PropTypes.string,
  imageWidth: PropTypes.string,
  loading: PropTypes.string,
  maxHeight: PropTypes.string,
  noPadding: PropTypes.bool,
  padding: responsiveAttributePropType,
};

export default ImageBlock;
