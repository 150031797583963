import styled, { css } from 'styled-components';
import Link from '@nubank/nuds-web/components/Link/Link';
import { breakpointsMedia } from '@nubank/nuds-web/styles/breakpoints';

/*  TODO: We might delete this styled component if we
    add text related CSS props to the Link component  */

export const StyledLink = styled(Link)`
  ${breakpointsMedia({
    md: css`
      text-align: center;
    `,
    lg: css`
      text-align: left;
    `,
  })}
`;

StyledLink.displayName = 'StyledLink';
